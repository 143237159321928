import customParseFormat from 'dayjs/plugin/customParseFormat'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import dayjs from 'dayjs'
import 'dayjs/locale/th'

dayjs.extend(customParseFormat)
dayjs.extend(duration)
dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)
dayjs.locale('th')

export const convertToDate = (date: string|null, format = 'DD-MM-YYYY'): string => {
	return date ? dayjs(date).format(format) : '-'
}

export const convertToTime = (time:string, format = 'HH:mm'): string => {
	return time ? dayjs(time).format(format) : '-'
}

export const formatDefault = (dateTime: string|null, format = 'DD-MM-YYYY HH:mm'): string => {
	return dateTime ? dayjs(dateTime).format(format) : '-'
}

export default dayjs
