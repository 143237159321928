export interface ImportPagePayload {
    shopId: string,
    pages: PageDetail[]
}

export interface PageDetail {
    pageId:      string;
    name:        string;
    accessToken: string;
    imageUrl?:    string;
}

export enum LIVE_STATUS {
    DRAFT = 'DRAFT',
    LIVE = 'LIVE',
    COMPLETED = 'COMPLETED',
}

export interface FilterLivePayload {
    pageId: string,
    status?: string|null;
    name?: any;
    limit: number;
    offset: number;
    startDate?: any;
    endDate?: any;
}

export enum SHIPMENT_STATUS {
    FREE = 'FREE',
    FLAT_RATE = 'FLAT_RATE',
  }
export interface CreateLivePayload {
    name:      string;
    title?:     string;
    fanpageId: string;
    courierId: string;
    shopId:    string;
    payment:   string[];
    bank:      string[];
    shipment: {
        status: SHIPMENT_STATUS,
        flatRatePrice: number,
    },
    status?:    string;
    products:  Product[];
}

export interface Product {
    product?:  string;
    price?:    number;
    cfCode?:   string;
    quantity?: number;
}

export enum LivePaymentType {
    COD = 'COD',
    TRANSFER = 'TRANSFER'
}

export interface EditLivePayload {
    name: string;
    fanpageId: string;
    courierId: string;
    shopId: string;
    shipment: {
        status: SHIPMENT_STATUS,
        flatRatePrice: number,
    },
    payment: LivePaymentType[];
    bank: string[];
    products?: Product[];
}

export interface GetLiveCommentParams {
    offset: number;
    limit: number;
}

export interface GetOrderParams {
    offset: number;
    limit: number;
    status: null|string;
}

export interface DeleteStatusRevokePage {
    success: boolean;
}