
import { computed, defineComponent, onMounted, PropType, ref } from 'vue'
import { useBreakpoints } from '@vueuse/core'
import { getBankList, BankDetail } from '@/api/bank.api'
import { addBankToShop, editBankInShop } from '@/api/shop.api'
import { useShopStore } from '@/pinia/shop/shop.pinia'
import { BANK_ACCOUNT_TYPE } from '@/config/bank'
export default defineComponent({
	name: 'BankAddDialog',
	props: {
		modelValue: {
			type: Boolean as PropType<boolean>,
			default: false,
		},
		accountName: {
			type: String as PropType<string>,
			default: ''
		},
		accountNumber: {
			type: String as PropType<string>,
			default: ''
		},
		accountIssuerId: {
			type: String as PropType<string>,
			default: ''
		},
		bankId: {
			type: [String, null] as PropType<string|null>,
			default: null
		},
		accountType: {
			type: String as PropType<string>,
			default: BANK_ACCOUNT_TYPE.BANK_ACCOUNT
		},
	},
	emits: ['update:modelValue', 'update:accountName', 'update:accountNumber', 'update:accountIssuerId', 'onSubmitBank','update:accountType'],
	setup (props, { emit }) {
		const shopStore = useShopStore()
		const breakpoints = useBreakpoints({
			tablet: 1024,
		})
		const isSmallerThanTablet = breakpoints.isSmaller('tablet')
		const addBankForm = ref()
		const bankIssuerList = ref<BankDetail[]>([])
		const isSubmitting = ref(false)
		const isReactNativeWebview = computed(() => window.ReactNativeWebView)

		onMounted(() => {
			fetchBankList()
		})

		const fetchBankList = () => {
			getBankList().then(response => {
				bankIssuerList.value = response.data
			})
		}

		const onSubmitBank = async () => {
			await addBankForm.value.validate()
			isSubmitting.value = true
			try {
				let payload:any = {
					shopId: shopStore.selectedShopId,
					bankId: props.bankId,
					accountName: props.accountName,
					accountNumber: props.accountNumber,
					accountType: props.accountType,
				}
				if (props.accountType === BANK_ACCOUNT_TYPE.BANK_ACCOUNT) {
					payload = {
						...payload,
						accountIssuerId: props.accountIssuerId,
					}
				}
				if (!props.bankId) {
				// add Mode
					await addBankToShop(payload)
				} else {
				// Edit Mode
					await editBankInShop(payload)
				}
				emit('update:accountName', '')
				emit('update:accountNumber', '')
				emit('update:accountIssuerId', '')
				emit('update:accountType', '')
				emit('update:modelValue', false)
				emit('onSubmitBank') // Trigger parent
			} catch (error) {
				console.error(error)
			} finally {
				isSubmitting.value = false
			}
		}

		return {
			addBankForm,
			fetchBankList,
			bankIssuerList,
			onSubmitBank,
			isSmallerThanTablet,
			isSubmitting,
			BANK_ACCOUNT_TYPE,
			isReactNativeWebview,
		}
	}
})
