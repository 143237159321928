
import { defineComponent, ref, computed, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import firebase from 'firebase/app'
import { ElNotification } from 'element-plus'
import ProductDetail from '@/components/product/ProductDetail.vue'
import { PRODUCT_LIST_PATH } from '@/constants/route'
import { createProduct, getProductDetail, editProduct, deleteProductById, editStockAndPriceService } from '@/api/products/product.service'
import { IProductListRes, IProductDetail } from '@/api/products/type'
import { LocationQueryValue } from 'vue-router'
import DialogWarning from '@/components/order/DialogWarning.vue'
import CustomUpload from '@/components/core/CustomUpload.vue'
import dayjs from 'dayjs'
import ProductVariations from '@/components/product/ProductVariations.vue'
import { IParamsProductForm } from '@/type/product'
import { useShopStore } from '@/pinia/shop/shop.pinia'
import { useUserStore } from '@/pinia/user/user.pinia'
import { ISubProduct, IPayloadSubProductForm } from '@/type/product'

export default defineComponent({
	name: 'ProductsForm',
	components: { ProductDetail, DialogWarning, CustomUpload, ProductVariations, },
	props: {
		isAnotherCreate: {
			type: Boolean,
			default: false
		},
		titleHeader: {
			type: String,
			default: ''
		}
	},
	emits: ['onCancelCreate', 'onCreateSuccess'],
	setup(props, { emit }) {
		const route = useRoute()
		const shopStore = useShopStore()
		const userStore = useUserStore()
		const router = useRouter()
		const currentShop = computed(() => shopStore.currentShop)
		const isLoadingPage = ref<boolean>(false)
		const isLoading = ref<boolean>(false)
		const ProductDetailDom = ref<any>(null)
		const productVariationsDom = ref<any>(null)
		const fileImage = ref<any>(null)
		const isEdit = ref<boolean>(false)
		const isWarning = ref<boolean>(false)
		const warningMessage = ref<string>('')
		const uploadImageRef = ref<any>(null)
		const productDetail = ref<IProductDetail>({
			name: '',
			sku: '',
			desc: '',
			weight: 0,
			price: 0,
			unit: 0
		})
		const isReactNativeWebview = computed(() => window.ReactNativeWebView)
		const imageProductUrl = ref<string>('')
		const textHeader = ref<string>('สร้างสินค้า')
		const duplicateSKU = ref<boolean>(false)
		const isVariation = ref<boolean>(false)
		const isMarketplace = ref<boolean>(false)
		const isFirstEdit = ref<boolean>(false)
		const variations = ref([])
		const subProducts = ref([])
		const isChangeIsVariationDetail = ref<boolean>(false)
		const productId = computed<string | LocationQueryValue[]>(() => {
			if(route.query.productID) {
				return route.query.productID
			}
			return ''
		})

		// const isVariationTypeAndSubProduct = computed(() => {
		// 	const newVariation = variations.value.filter((e: { name: string, list: string[]}) => { return e.list.length > 0})
		// 	return newVariation.length > 0 && subProducts.value.length > 0
		// })

		const callApiCreateAndEdit = async(params: IParamsProductForm) => {
			try {
				isLoading.value = true
				if (isEdit.value) {
					let newParams = params
					if (isVariation.value) {
						const mapSubProduct = params.subProducts?.map((e: any) => {
							return {
								...e,
								sku: e.sku
							}
						})
						newParams = {
							...params,
							subProducts: mapSubProduct
						}
					}
					const res: IProductListRes = await editProduct(productId.value, newParams)
					if(res){
						ElNotification({
							title: 'สำเร็จ',
							message: `แก้ไขสินค้า ${productDetail.value.name} สำเร็จ`,
							type: 'success',
							showClose: !isReactNativeWebview.value
						})
						router.push({ path: PRODUCT_LIST_PATH,query:{activeName:'product'}})
					}
				}else {
					const res: IProductListRes = await createProduct(params)
					if(res){
						ElNotification({
							title: 'สำเร็จ',
							message: `เพิ่มสินค้า ${productDetail.value.name} สำเร็จ`,
							type: 'success',
							showClose: !isReactNativeWebview.value
						})
						if (props.isAnotherCreate) {
							emit('onCreateSuccess', res)
						} else {
							router.push({ path: PRODUCT_LIST_PATH,query:{activeName:'product'}})
						}
					}
				}
			} catch (error: any) {
				isLoading.value = false
				if (error.response?.data?.resDesc === 'DUPLICATE_PRODUCT_SKU') {
					if (error.response?.data?.skus?.length) {
						subProducts.value.forEach((e: ISubProduct) => {
							if (error.response?.data?.skus?.includes(e.sku)) {
								e.isValidSku = true
							} else {
								e.isValidSku = false
							}
						})
					} else {
						duplicateSKU.value = true
					}
					ElNotification({
						title: 'ผิดพลาด',
						message: 'SKU ซ้ำ! กรุณากรอก SKU ใหม่อีกครั้ง',
						type: 'error',
						showClose: !isReactNativeWebview.value
					})
				}else {
					ElNotification({
						title: 'ผิดพลาด',
						message: 'ระบบเกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง',
						type: 'error',
						showClose: !isReactNativeWebview.value
					})
				}
				await ProductDetailDom.value.clearValidate()
				await ProductDetailDom.value.validateAll()
			}
			isLoading.value = false
		}

		const submitForm = async () => {
			if (isDuplicateSkuSubProduct.value && isVariation.value) {
				return
			}
			try {
				let isValidate = false
				isLoading.value = true

				if (isMarketplace.value) {
					try {
						const payload = {
							productId: productId.value as string,
							price: +productDetail.value?.price || 0,
							unit: +productDetail.value?.unit || 0,
						}
						// ปรับสต็อก marketplace
						await editStockAndPriceService(payload)
						ElNotification({
							title: 'สำเร็จ',
							message: `แก้ไขสินค้า ${productDetail.value.name} สำเร็จ`,
							type: 'success',
							showClose: !isReactNativeWebview.value
						})
						router.push({ path: PRODUCT_LIST_PATH,query:{activeName:'product'}})
					} catch (error:any) {
						// TODO: create Dialog show error message
						console.log('error.response', error.response)

						ElNotification({
							title: 'ผิดพลาด',
							message: 'ระบบเกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง',
							type: 'error',
							showClose: !isReactNativeWebview.value
						})
					}
					isLoading.value = false
					return
				}

				if(isVariation.value){
					const [ validateInfo, validateSubProduct ] = await Promise.all([ProductDetailDom.value?.validateAll(), productVariationsDom.value?.validateSubProductValidateForm()])
					isValidate = validateInfo && validateSubProduct
				}else {
			    	isValidate = await ProductDetailDom.value?.validateAll()
				}
				if (isValidate) {
					let imagePathUrl = ''
					if (fileImage.value && fileImage.value.file) {
						const uploadPath = firebase.storage().ref().child(`product/${userStore.userData?.uid}/${currentShop.value?._id}/${productDetail.value.name + dayjs().format('YYYY-MM-DD-HH:MM')}-${dayjs().unix()}`)
						const snapshot = await uploadPath.put(fileImage.value.file)
						imagePathUrl = await snapshot.ref.getDownloadURL()
					}
				
					
					let params: IParamsProductForm = {
						name: productDetail.value.name,
						desc: productDetail.value.desc,
						sku: productDetail.value.sku,
						weight: +productDetail.value.weight,
						price: +productDetail.value.price,
						costPrice: productDetail.value.costPrice || 0,
						unit: +productDetail.value.unit,
						shopId: currentShop.value?._id,
						images: imagePathUrl ? [imagePathUrl] : [imageProductUrl.value]
					}
					if (isVariation.value) {
						params.variations = variations.value ? variations.value.filter((e: { name: string, list: string[]}) => { return e.list.length > 0}) : []
						const newSubProduct = subProducts.value.map(async(e: any) => {
							if (e.images[0] && e.images[0].raw) {
								const uploadPath = firebase.storage().ref().child(`product/${userStore.userData?.uid}/${currentShop.value?._id}/sub-product/${e.sku + dayjs().format('YYYY-MM-DD-HH:MM')}-${dayjs().unix()}`)
								const snapshot = await uploadPath.put(e.images[0].raw)
								const imagePathUrl = await snapshot.ref.getDownloadURL()
								e.images[0] = imagePathUrl
							}
							let payload: IPayloadSubProductForm  = {
								sku: e.sku,
								types: e.types,
								weight: e.weight,
								price: e.price,
								costPrice: e.costPrice || 0,
								unit: e.unit,
								images: e.images,
							}
							if (isEdit.value) {
								payload.types = e.typesName.split(','),
								payload._id = e._id
							}
							return payload
						})
					    const resolvePromise = await Promise.all(newSubProduct)
						params.subProducts = resolvePromise
						await callApiCreateAndEdit(params)
					} else {
						await callApiCreateAndEdit(params)
					}
				}
			} catch (error: any) {
				isLoading.value = false
			}
		}

		const textBtnCreate = computed( () => {
			return isEdit.value ?'บันทึกการแก้ไข' : 'สร้างสินค้า'
		})

		const onGetProductDetail = async() => {
			try {
				isLoading.value = true
				const res = await getProductDetail(productId.value)
				if (res) {
					isMarketplace.value = res.marketplaces || res.subProducts.some((item:any) => item.marketplaces)

					const img = res.images?.[0] || ''
					productDetail.value.name = res.name
					productDetail.value.sku = res.sku
					productDetail.value.desc = res.desc
					productDetail.value.weight = res.weight
					productDetail.value.price = res.price
					productDetail.value.costPrice = res.costPrice
					productDetail.value.unit = res.unit
					fileImage.value = img
					imageProductUrl.value = img

					if (res.subProducts?.length > 0) {
						isVariation.value = true
						isFirstEdit.value = true
						variations.value = res.variations 
						subProducts.value = res.subProducts.map((e: any) => {
							return {
								sku: e.sku,
								types: e.types,
								typesName: e.types?.join(),
								weight: e.weight,
								price: e.price,
								costPrice: e.costPrice,
								unit: e.unit,
								images: e.images,
								imageUrl: e.images?.[0] || '',
								_id: e._id
							}
						})
					}
					await ProductDetailDom.value?.clearValidate()
					isLoading.value = false
				}
			} catch (error) {
				console.log('get product detail error:', error)
				isLoading.value = false
				ElNotification({
					title: 'ผิดพลาด',
					message: 'ระบบเกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง',
					type: 'error',
					showClose: !isReactNativeWebview.value
				})
			}
		}

		const onBackProductList = () => {
			if (props.isAnotherCreate) {
				emit('onCancelCreate')
			} else {
			    router.push({ path: PRODUCT_LIST_PATH, query:{activeName:'product'}})
			}
		}

		const onDeleteProduct = async() => {
			try {
				const payload = {
					productId: productId.value,
					isDeleteProductOnMarketplace: false
				}
				const res = await deleteProductById(payload)
				if(res.updateStatus === 'SUCCESSFUL'){
			        router.push({ path: PRODUCT_LIST_PATH, query:{activeName:'product'}})
					ElNotification({
						title: 'สำเร็จ',
						message: `ลบสินค้า ${productDetail.value.name} สำเร็จ`,
						type: 'success',
						showClose: !isReactNativeWebview.value
					})
				}
			} catch (_) {
				ElNotification({
					title: 'เกิดข้อผิดพลาด',
					message: 'ระบบเกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง',
					type: 'error',
					showClose: !isReactNativeWebview.value
				})
			}
		}

		const onConfrimDelete = () => {
			isWarning.value = true
			warningMessage.value = `คุณต้องการลบสินค้า "${productDetail.value.name}"`
		}

		const isDuplicateSkuSubProduct = computed(() => {
			subProducts.value.forEach((e: ISubProduct) => {
				const newSkuArray = subProducts.value.filter((item: ISubProduct) => item._id !== e._id).map((e: ISubProduct) => { return e.sku })
				if (e.sku === productDetail.value.sku || newSkuArray.includes(e.sku)) {
					e.isValidSku = true
				} else {
					e.isValidSku = false
				}
			})
			const isDuplicate = subProducts.value.some((e: ISubProduct) => {
				return e.isValidSku
			})
			return isDuplicate || false
		})

		onMounted(async () => {
			try {
				if (productId.value) {
					isEdit.value = true
					textHeader.value = 'แก้ไขสินค้า'
					isLoadingPage.value = true
					await onGetProductDetail()
				}
			} finally {
				isLoadingPage.value = false
			}
		})

		return {
			isLoadingPage,
			isLoading,
			currentShop,
			ProductDetailDom,
			productDetail,
			productId,
			isEdit,
			submitForm,
			onBackProductList,
			onGetProductDetail,
			onDeleteProduct,
			isWarning,
			warningMessage,
			onConfrimDelete,
			uploadImageRef,
			fileImage,
			imageProductUrl,
			textBtnCreate,
			textHeader,
			duplicateSKU,
			isVariation,
			isMarketplace,
			variations,
			subProducts,
			isFirstEdit,
			isChangeIsVariationDetail,
			productVariationsDom,
			isDuplicateSkuSubProduct
		}
	}
})
