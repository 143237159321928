import axios from '../instance'
import {
	CFPostDetailResponse,
	FetchRequest,
	CreatePayload,
	GetOrderParams,
	FetchPostInPageRequest,
} from './type'
import { EditPayload } from '@/api/cf-post/type'

export const fetchPostCFList = async (params: FetchRequest): Promise<any> => {
	try {
		const response = await axios.get('/post-cf', {
			params,
		})
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const fetchPostCFDetail = async (
	postId: string
): Promise<CFPostDetailResponse> => {
	try {
		const { data } = await axios.get<CFPostDetailResponse>(
			`/post-cf/${postId}`
		)
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}

export const fetchPostInPage = async (
	params: FetchPostInPageRequest
): Promise<any> => {
	try {
		const response = await axios.get('/post-cf/post', { params })
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const postCFEdit = async (
	payload: Omit<EditPayload, 'pageId' | 'shopId'>
): Promise<any> => {
	try {
		const response = await axios.put('/post-cf/edit', payload)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const postCFCreate = async (payload: CreatePayload): Promise<any> => {
	try {
		const response = await axios.post('/post-cf/create', payload)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const deletePosCF = async (postId: string): Promise<any> => {
	try {
		const { data } = await axios.delete(`/post-cf/delete/${postId}`)
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}

export const updateEnablePostCF = async (postId: string): Promise<any> => {
	try {
		const { data } = await axios.patch(`/post-cf/update-enable/${postId}`)
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}

export const endPostCf = async (cfId: string): Promise<any> => {
	try {
		const response = await axios.post(`/post-cf/${cfId}/end-cf`)
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getPostCfOrder = async (
	cfId: string,
	{ limit = 25, offset = 1, status }: GetOrderParams
): Promise<any> => {
	try {
		const response = await axios.get(`/post-cf/${cfId}/orders`, {
			params: {
				limit,
				offset,
				status,
			},
		})
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}
