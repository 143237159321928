import { defineStore } from 'pinia'
import { State } from '@/pinia/order/types'
import { IArgumentProductList, IProductListRes } from '@/api/products/type'

import {
	getOrderProductList,
	getOrderProductSetList,
	getInboxFacebookList,
} from '@/api/order/order.service'
import {
	IGetUserFacebookParams,
	IGetUserFacebookForm,
} from '@/api/order/type'
export const useOrderStore = defineStore('order', {
	state: (): State => ({
		orderProductPage: 1,
		orderProductTotalPage: 1,
		orderProductSetPage: 1,
		orderProductSetTotalPage: 1,
		orderProductList: [],
		orderProductSetList: [],
		inboxAfterToken: {
			pageId: '',
			afterToken: null,
		},
	}),
	actions: {
		// old mutations
		updateOrderProductPage(orderProductPage: number) {
			this.orderProductPage = orderProductPage
		},
		updateOrderProductTotalPage(orderProductTotalPage: number) {
			this.orderProductTotalPage = orderProductTotalPage
		},
		updateOrderProductSetPage(orderProductPage: number) {
			this.orderProductSetPage = orderProductPage
		},
		updateOrderProductSetTotalPage(orderProductTotalPage: number) {
			this.orderProductSetTotalPage = orderProductTotalPage
		},
		updateOrderProductList(orderProductSetList: Array<IProductListRes>) {
			this.orderProductList = orderProductSetList
		},
		updateOrderProductSetList(orderProductSetList: Array<IProductListRes>) {
			this.orderProductSetList = orderProductSetList
		},
		// old actions
		async getOrderProductListStore(params: IArgumentProductList) {
			try {
				const { data, pagination } = await getOrderProductList(params)
				this.updateOrderProductPage(pagination.pages)
				this.updateOrderProductTotalPage(pagination.totals)
				this.updateOrderProductList(data)
			} catch (error) {
				return Promise.reject(error)
			}
		},
		async getOrderProductSetListStore(params: IArgumentProductList) {
			try {
				const { data, pagination } = await getOrderProductSetList(
					params
				)
				this.updateOrderProductSetPage(pagination.pages)
				this.updateOrderProductSetTotalPage(pagination.totals)
				this.updateOrderProductSetList(data)
			} catch (error) {
				return Promise.reject(error)
			}
		},
		// Api call
		async actionGetInboxList(params: IGetUserFacebookForm) {
			try {
				const response = await getInboxFacebookList({
					...params,
					afterTokens: this.inboxAfterToken.afterToken,
				})
				this.inboxAfterToken = response.afterTokens
				
				return response
				

			} catch (error) {
				return Promise.reject(error)
			}
		},
	},
})
