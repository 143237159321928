import axios from '../instance'
import {
	IParamsGetOrderList,
	IbodyDataOrderCreate,
	IBodyChangeStatusOrder,
	ImportDetectPayload,
	ImportOrderPayload,
	LogVerifySlipParams,
	IGetUserFacebookParams,
	IFormFacebook,
} from './type'
import { LocationQueryValue } from 'vue-router'
import { AxiosResponse } from 'axios'
import { IArgumentProductList } from '../products/type'

export const getOrderList = async (params: IParamsGetOrderList) => {
	try {
		const { data } = await axios.get('/order', { params: { ...params } })
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}

export const orderCreation = async (bodyData: IbodyDataOrderCreate) => {
	try {
		const { data } = await axios.post('/order/create', bodyData)
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getOrderDetail = async (
	orderId: string | LocationQueryValue[]
) => {
	try {
		const response = await axios.get(`/order/detail/${orderId}`)
		return response.data
	} catch (error) {
		return Promise.reject(error)
	}
}

export const orderEdit = async (
	orderId: string | LocationQueryValue[],
	bodyData: IbodyDataOrderCreate
) => {
	try {
		const response = await axios.put(`/order/edit/${orderId}`, bodyData)
		return response.data
	} catch (error) {
		return Promise.reject(error)
	}
}

export const updateStatusOrder = async (bodyData: IBodyChangeStatusOrder) => {
	try {
		const response = await axios.put('/order/status/update', bodyData)
		return response.data
	} catch (error) {
		return Promise.reject(error)
	}
}

export const deleteOrderById = async (
	productId: string | LocationQueryValue | LocationQueryValue[]
) => {
	try {
		const { data } = await axios.delete(`/order/delete/${productId}`)
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}

export const importDetect = async (
	payload: ImportDetectPayload
): Promise<AxiosResponse> => {
	try {
		const { data } = await axios.post('/order/import-detect', payload)
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}

export const importOrder = async (
	payload: ImportOrderPayload
): Promise<AxiosResponse> => {
	try {
		const { data } = await axios.post('/order/import', payload)
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getOrderProductList = async (params: IArgumentProductList) => {
	try {
		const { data } = await axios.get('/product/order', {
			params: { ...params },
		})
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getOrderProductSetList = async (params: IArgumentProductList) => {
	try {
		const { data } = await axios.get('/product/set/list', {
			params: { ...params },
		})
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}

export const deleteOrders = async (
	orders: string[]
): Promise<AxiosResponse> => {
	try {
		const { data } = await axios.post('/order/delete', { orders })
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}

export const orderStockCheckList = async (payload:{
	shopId: string,
	orders: string[]

}): Promise<AxiosResponse> => {
	try {
		const { data } = await axios.post('/order/stock-check-list', {
			...payload
		})
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}

export const orderExpedite = async (
	orderId: string
): Promise<AxiosResponse> => {
	try {
		const { data } = await axios.put(`/order/expedite/${orderId}`)
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getSummaryOrderCost = async (
	orders: string[],
	shopId: string
): Promise<any> => {
	try {
		const { data } = await axios.post('/order/summary-cost', {
			orders,
			shopId,
		})
		return data
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getLogVerifySlipApi = async (params: LogVerifySlipParams): Promise<AxiosResponse> => {
	try {
		const response = await axios.get('/verify-slip', { params })
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}

export const getInboxFacebookList = async (data: IGetUserFacebookParams): Promise<IFormFacebook> => {
	try {
	  const response = await axios.post<IFormFacebook>('/order/page-inbox', data)
	  return response.data
	} catch (error) {
	  // ทำการจัดการข้อผิดพลาด
	  console.error('Error in getInboxFacebookList:', error)
	  return Promise.reject(error)
	}
}
