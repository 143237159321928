import { defineStore } from 'pinia'
import {
	fetchPostCFDetail,
	fetchPostCFList,
	fetchPostInPage,
	postCFCreate,
	postCFEdit,
} from '@/api/cf-post/cf-post.api'
import { FeedDetail, IBank, PostType } from './type'
import { SHIPMENT_STATUS } from '@/api/live/live.type'
import {
	CreatePayload,
	EditPayload,
	IProductListRes,
	Product,
} from '@/api/cf-post/type'
import { useShopStore } from '@/pinia/shop/shop.pinia'
import dayjs from 'dayjs'
import firebase from 'firebase'
import product from '../../router/product'

export const useCFPost = defineStore('cf-post', {
	state: () => ({
		postTypeSelect: PostType.BY_POST,
		shopId: '',
		postCFId: '',
		name: '',
		status: '',
		courier: '',
		paymentType: ['TRANSFER'],
		bankList: [] as IBank[],
		bank: [] as string[],
		shipmentStatus: SHIPMENT_STATUS.FREE,
		shipmentFlatRate: 0,
		customerMessage: [{ message: '' }],
		hashTag: [],
		isLikeComment: false,
		isNotDuplicateReplyUser: false,
		isHideComment: false,
		replyMessage: '',
		chatReplyMessage: '',
		imageUrl: '',
		imageReply: null as any,
		CFPostPage: {
			cfList: [],
			limit: 10,
			offset: 1,
			pages: 0,
		},
		postInPage: {
			list: [],
			limit: 10,
			offset: 1,
			pages: 0,
			afterToken: null,
		},
		selectedPost: [] as FeedDetail[],
		products: [] as Product[] | any,
		postIds: [] as string[],
		pageId: '',
	}),

	getters: {
		displayReplyPreivewImage(state) {
			if (state.imageReply instanceof File) {
				return URL.createObjectURL(state.imageReply)
			}
			return state.imageReply
		},
	},
	actions: {
		async actionGetCFPostList(pageId: string) {
			const params = {
				pageId,
				limit: this.CFPostPage.limit,
				offset: this.CFPostPage.offset,
			}

			const { data: response } = await fetchPostCFList(params)

			this.CFPostPage.cfList = response.data
			this.CFPostPage.pages = response.pagination.pages
		},
		async actionGetCFPostDetail(postId: string) {
			const r = await fetchPostCFDetail(postId)

			this.postTypeSelect = r.type
			this.name = r.name
			this.status = r.status
			this.isLikeComment = r.isLikeComment
			this.isHideComment = r.isHideComment
			this.replyMessage = r.messageReply
			this.imageReply = r.imageReply
			this.imageUrl = r?.imageUrl || ''
			this.selectedPost = r.posts
			this.products = r.products
			this.courier = r.courierId
			this.paymentType = r.payment
			this.shipmentStatus = r.shipment.status
			this.shipmentFlatRate = r.shipment.flatRatePrice
			this.pageId = r.pageId
			this.postIds = r.postIds
			this.shopId = r.shopId
			this.postCFId = r._id
			this.bank = r.bank
		},
		async actionGetPostInPage(pageId: string) {
			const params = {
				pageId,
				limit: this.postInPage.limit,
				offset: this.postInPage.offset,
				afterToken: this.postInPage.afterToken,
				postCFId: this.postCFId,
			}
			const { data: response } = await fetchPostInPage(params)


			const findSelected = response.data.find((item:any) => item?.postId === this.postIds?.[0]?.toString())?.list?.[0]?.cover

			this.imageUrl = findSelected || ''

			this.postInPage.list = [
				...this.postInPage.list,
				...response.data,
			] as any

			this.postInPage.afterToken = response.afterToken
		},
		async actionCreateCFPost({
			pageId,
			shopId,
		}: {
			pageId: string
			shopId: string
		}) {
			const _product = this.products.map((product: any) => {
				const _productSet = product.productSet?.map((_set: any) => {
					if (_set.product) {
						return {
							productId: _set.product.productId,
							name: _set.product?.name || _set.product?.sku,
							sku: _set.product?.sku,
							price: _set.product?.price,
							unit: _set?.unit,
						}
					}
				})

				product.price = Number(product.price || 0)

				if (
					_productSet &&
					_productSet.length > 0 &&
					!_productSet.includes(undefined)
				) {
					product.productSet = _productSet
					return product
				} else {
					product.productSet = []
					return product
				}
			})

			const createPayload = {
				postIds: this.selectedPost.map((post) => post.postId),
				type: this.postTypeSelect,
				name: 'บางโพสต์',
				imageUrl: this.imageUrl,
				messageReply: this.replyMessage,
				isLikeComment: this.isLikeComment,
				isHideComment: this.isHideComment,
				bank: this.bank,
				courierId: this.courier,
				payment: this.paymentType,
				products: _product,
				shipment: {
					status: this.shipmentStatus,
					flatRatePrice: this.shipmentFlatRate,
				},
				shopId,
				pageId,
			} as CreatePayload

			if (this.imageReply) {
				const imagePath = await this.actionUploadReplyImage(
					this.imageReply
				)
				createPayload.imageReply = imagePath
			}

			await postCFCreate(createPayload)
		},
		async actionEditCFPost({ postCFId }: any) {
			const _product = this.products.map((product: any) => {
				const _productSet = product.productSet?.map((_set: any) => {
					if (_set.product) {
						return {
							productId: _set.product.productId,
							name: _set.product?.name || _set.product?.sku,
							sku: _set.product?.sku,
							price: _set.product?.price,
							unit: _set?.unit,
						}
					}
				})

				product.price = Number(product.price || 0)

				if (
					_productSet &&
					_productSet.length > 0 &&
					!_productSet.includes(undefined)
				) {
					product.productSet = _productSet
					return product
				} else {
					return product
				}
			})

			const editPayload = {
				postIds: this.selectedPost.map((post) => post.postId),
				type: this.postTypeSelect,
				name: this.name,
				postCFId,
				imageUrl: this.imageUrl,
				messageReply: this.replyMessage,
				isLikeComment: this.isLikeComment,
				isHideComment: this.isHideComment,
				bank: this.bank,
				courierId: this.courier,
				payment: this.paymentType,
				products: _product,
				shipment: {
					status: this.shipmentStatus,
					flatRatePrice: this.shipmentFlatRate,
				},
			} as Omit<EditPayload, 'pageId' | 'shopId'>

			if (this.imageReply) {
				const imagePath = await this.actionUploadReplyImage(
					this.imageReply
				)
				editPayload.imageReply = imagePath
			}

			await postCFEdit(editPayload)
		},
		updateCfProducts(
			postCFProducts: Array<IProductListRes | Product> = []
		) {
			this.products = postCFProducts
		},
		removeCfProductById(postCFProductsID: string) {
			this.products = this.products.filter((e: Product) => {
				return e.product !== postCFProductsID
			})
		},
		async actionUploadReplyImage(image: File | string | null | undefined) {
			const shopStore = useShopStore()
			if (!image) {
				return image
			}
			if (!(image instanceof File)) {
				return image
			}
			const time = dayjs().format('YYYY-MM-DD-HH-mm')
			const [fileName, fileExtension] = (image as any).name.split('.')
			const path = `page-comment-reply/${
				shopStore.selectedShopId
			}/${time}/${dayjs().unix()}-${fileName}.${fileExtension}`
			const storage = firebase.storage().ref().child(path)
			return storage.put(image).then(async (snapshot) => {
				return await snapshot.ref.getDownloadURL()
			})
		},
	},
})
