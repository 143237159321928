
import { defineComponent, ref, computed, watch, watchEffect } from 'vue'
import { useReplaceInput } from '@/use/useReplaceInput'
import { useRouter } from 'vue-router'
import { IProductDetail } from '@/api/products/type'
import { useShopStore } from '@/pinia/shop/shop.pinia'

export default defineComponent({
	name: 'ProductCreateForm',
	props: {
		productName: {
			type: String,
			default: '',
		},
		productSKU: {
			type: String,
			default: '',
		},
		productDesc: {
			type: String,
			default: '',
		},
		productWeight: {
			type: Number,
			default: 0,
		},
		productPrice: {
			type: Number,
			default: 0,
		},
		productCostPrice: {
			type: Number,
		},
		productUnit: {
			type: Number,
			default: 0,
		},
		duplicateSKU: {
			type: Boolean,
			default: false,
		},
		isVariation: {
			type: Boolean,
			default: false,
		},
		isEdit: {
			type: Boolean,
			default: false,
		},
		isMarketplace: {
			type: Boolean,
			default: false,
		},
	},
	emits: [
		'update:productName',
		'update:productSKU',
		'update:productDesc',
		'update:productWeight',
		'update:productPrice',
		'update:productCostPrice',
		'update:productUnit',
		'update:duplicateSKU',
		'update:isVariation',
		'update:isChangeIsVariationDetail',
	],
	setup(props, { emit }) {
		const router = useRouter()
		const { decimalValueChecker, onPasteTextNumber, onPasteNumber } =
			useReplaceInput()
		const productDetailRef = ref<any>(null)
		const productDetail = ref<IProductDetail>({
			name: '',
			sku: '',
			desc: '',
			weight: 100,
			price: 0,
			unit: 0,
		})
		const shopStore = useShopStore()
		const currentShop = computed(() => shopStore.currentShop)
		const weightKg = computed(() => {
			return productDetail.value.weight / 1000
		})
		const isVariationDetail = ref<boolean>(false)

		const validateSKU = (rule: any, value: string, callback: any) => {
			if (value === '') {
				callback(new Error('กรุณากรอก SKU'))
			} else if (value.length > 13) {
				callback(new Error('กรุณากรอก SKU ไม่เกิน 13 ตัวอักษร'))
			} else if (value && props.duplicateSKU) {
				callback(new Error('SKU ซ้ำกรุณากรอก SKU ใหม่'))
			} else {
				callback()
			}
		}

		const validateWeight = (rule: any, value: number, callback: any) => {
			if (value === 0) {
				callback(new Error('กรุณากรอก น้ำหนักสินค้า'))
			} else if (value <= 0) {
				callback(new Error('กรุณากรอกน้ำหนักตั้งแต่หนึ่งกรัมขึ้นไป'))
			} else {
				callback()
			}
		}

		const validatePrice = (rule: any, value: number, callback: any) => {
			if (value === 0) {
				callback(new Error('กรุณากรอก ราคาสินค้า'))
			} else if (value <= 0) {
				callback(new Error('กรุณากรอกราคาตั้งแต่บาทขึ้นไป'))
			} else {
				callback()
			}
		}

		const rules = ref({
			name: [
				{
					required: true,
					message: 'กรุณากรอก ชื่อสินค้า',
					trigger: ['change', 'blur'],
				},
				{
					max: 100,
					message: 'กรุณากรอก ชื่อสินค้า ไม่เกิน 100 ตัวอักษร',
					trigger: ['change', 'blur'],
				},
			],
			sku: [
				{
					required: true,
					validator: validateSKU,
					trigger: ['change', 'blur'],
				},
			],
			desc: [
				{
					max: 255,
					message: 'กรุณากรอก รายละเอียดสินค้า ไม่เกิน 255 ตัวอักษร',
					trigger: ['change', 'blur'],
				},
			],
			weight: [
				{
					required: true,
					validator: validateWeight,
					trigger: ['change', 'blur'],
				},
			],
			price: [
				{
					required: true,
					validator: validatePrice,
					trigger: ['change', 'blur'],
				},
			],
			unit: [
				{
					required: true,
					message: 'กรุณากรอก จำนวนสินค้า',
					trigger: ['change', 'blur'],
				},
			],
		})

		const validateAll = async () => {
			const isValid = await productDetailRef.value?.validate()
			return isValid
		}

		const clearValidate = async () => {
			await productDetailRef.value?.clearValidate()
		}

		const onChangeIsVariationDetail = () => {
			emit('update:isChangeIsVariationDetail', true)
		}

		watch(
			() => productDetail.value.name,
			(value) => emit('update:productName', value)
		)
		watch(
			() => productDetail.value.sku,
			(value) => {
				emit('update:productSKU', value)
				emit('update:duplicateSKU', false)
			}
		)
		watch(
			() => productDetail.value.desc,
			(value) => emit('update:productDesc', value)
		)
		watch(
			() => productDetail.value.weight,
			(value) => emit('update:productWeight', +value)
		)
		watch(
			() => productDetail.value.price,
			(value) => emit('update:productPrice', +value)
		),
		watch(
			() => productDetail.value.costPrice,
			(value) => emit('update:productCostPrice', value)
		)
		watch(
			() => productDetail.value.unit,
			(value) => emit('update:productUnit', +value)
		)
		watch(
			() => isVariationDetail.value,
			(value) => emit('update:isVariation', value)
		)

		const assignValue = () => {
			productDetail.value.name = props.productName
			productDetail.value.sku = props.productSKU
			productDetail.value.desc = props.productDesc
			productDetail.value.weight = props.productWeight
			productDetail.value.price = props.productPrice
			productDetail.value.costPrice = props.productCostPrice
			productDetail.value.unit = props.productUnit
			isVariationDetail.value = props.isVariation
		}

		watchEffect(() => {
			if (productDetail?.value) {
				assignValue()
			}
		})
		
		const onReplaceSpecialChar = (text: string) => {
			productDetail.value.sku = text.replaceAll(' ', '')
		}

		return {
			productDetail,
			weightKg,
			router,
			currentShop,
			productDetailRef,
			rules,
			isVariationDetail,
			onReplaceSpecialChar,
			onPasteTextNumber,
			decimalValueChecker,
			validateAll,
			assignValue,
			clearValidate,
			validateSKU,
			onChangeIsVariationDetail,
			onPasteNumber,
		}
	},
})
