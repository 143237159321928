export const getProductName = (item: any): string | undefined => {
	return item.marketplaces?.[0]?.name || item.name || item?.parent?.name || item?.parentInfo?.name || '-'
}

export const getProductTypes = (item: any): string | undefined => {
	return item.marketplaces?.[0]?.types?.join('-') || item.types?.join('-')
}

export const getProductNameWithTypes = (item: any): string => {
	const name = getProductName(item)
	const types = getProductTypes(item)
	return types ? `${name}, (${types})` : `${name}`
}