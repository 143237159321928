export type FeedDetail = {
    postId: string
    message: string
    likeTotalCount: number
    list: PostImage[]
    commentTotalCount: number
    shareTotalCount: number
    createdAt: Date|string
}


export enum PostType {
    BY_POST = 'BY_POST'
}

export type PostImage = {
    cover: string
    type: string
}

export interface IBank {
    _id:           string;
    deleted:       boolean;
    deletedAt:     null;
    accountName:   string;
    accountNumber: string;
    accountIssuer: AccountIssuer;
    accountType:    string;
    updatedAt:     Date;
    createdAt:     Date;
}

export interface AccountIssuer {
    _id:   string;
    name:  string;
    code:  string;
    image: string;
}
