
import { defineComponent, ref, computed, watch, onMounted } from 'vue'
import { useReplaceInput } from '@/use/useReplaceInput'
import { ElNotification } from 'element-plus'
import { ISubProduct } from '@/type/product'
export default defineComponent({
	name: 'ProductVariations',
	props: {
		productDetail: {
			type: Object,
			default: () => {
				return {
					name: '',
					sku: '',
					desc: '',
					weight: 0,
					price: 0,
					unit: 0
				}
			}
		},
		isVariations: {
			type: Boolean,
			default: false
		},
		variations: {
			type: Array,
			default: () => []
		},
		subProducts: {
			type: Array,
			default: () => []
		},
		isFirstEdit: {
			type: Boolean,
			default: false
		},
		isEdit: {
			type: Boolean,
			default: false
		},
		isChangeIsVariationDetail: {
			type: Boolean,
			default: false
		},

	},
	emits: [
		'update:variations',
		'update:subProducts',
		'update:isFirstEdit',
	],
	setup (props, { emit }) {
		const { decimalValueChecker } = useReplaceInput()
		const isReactNativeWebview = computed(() => window.ReactNativeWebView)
		const productVariationRef = ref<any>(null)
		const subProductValidateForm = ref<any>(null)
		const variationDetail = ref<{variationsType: { name: string; list: string[];}[]}>({
			variationsType: [
				{
					name: '',
					list: []
				}
			]
		})
		const variableTypeArr = ref<{ value: string; label: string; }[]>([{
			label: 'ไซต์ s', value: 'ไซต์ s'
		}])
		const subProductArr = ref<Array<ISubProduct>>([])
		const isChangeEditVariation = computed(() => {
			return props.isChangeIsVariationDetail
		})
		const addTypeVariation = () => {
			if(variationDetail.value.variationsType.length < 4) {
				variationDetail.value.variationsType.push({name: '', list: []})
			}
		}

		const onHandlePrice = (item: any) => {
			item.price = item.price > 0 ? item.price : 1
		}
		const validateListVariation = (rule: any, value: string[], callback: any) => {
			if (value.length <= 0) {
				callback(new Error('กรุณากรอก ตัวเลือกสินค้า'))
			} else {
				callback()
			}
		}

		const rules = ref({
			variationsType: [{
				name: [{ required: true, message: 'กรุณากรอก หมวดหมู่สินค้า', trigger: ['change', 'blur']}],
				list: [{ required: true, validator: validateListVariation, trigger: ['change', 'blur']}]
			}]
		})

		const validatePriceVariation = (rule: any, value: number, callback: any) => {
			if (value <= 0) {
				callback(new Error('กรุณากรอก ราคาสินค้าย่อย'))
			} else {
				callback()
			}
		}

		const removeVariationType = (index: number) => {
			if(variationDetail.value.variationsType.length > 1) {
				variationDetail.value.variationsType.splice(index, 1)
			}
		}

		const removeSubProduct = (index: number) => {
			subProductArr.value.splice(index, 1)
			if(subProductArr.value.length === 1 && !props.isEdit){
				variationDetail.value.variationsType = []
				variableTypeArr.value = []
				variableTypeArr.value = [{label: 'ไซต์ s', value: 'ไซต์ s'}]
				addTypeVariation()
			}
		}

		const isLastVariationsTypeEmpty = computed(() => {
			const lastIndex = variationDetail.value.variationsType.length - 1
			return variationDetail.value.variationsType[lastIndex].name === '' || variationDetail.value.variationsType[lastIndex].list.length < 1
		})

		const variationsTypeArr = computed(() => {
			return variationDetail.value.variationsType.length > 0 ? variationDetail.value.variationsType.map(e => { return [...e.list ]}) : [{name: '',list: []}]
		})

		const cartesianSubProduct = (item: any) => {
			return item.reduce((a: any,b: any) => {
				return a.map((x: any) =>{
					return b.map((y: any) =>{
						return x.concat([y])
					})
				}).reduce((a: any,b: any) => { return a.concat(b) },[])
			}, [[]])
		}

		const mapCartesianSubProduct = (value: any) => {
			return cartesianSubProduct(value).map((e: string[]) => {
				return {
					sku: '',
					types: e,
					typesName: e.join(),
					weight: props.productDetail.weight,
					price: props.productDetail.price,
					costPrice: props.productDetail.costPrice,
					unit: props.productDetail.unit,
					images: [''],
					isValidSku: false
				}
			})
		}

		const addOptionVariations = (event: string[]) => {
			const test = event.map(e => {
				return {
					value: e,
         			label: e
				}
			})
			const lastIndexType = test.length - 1
			variableTypeArr.value.push(test[lastIndexType])
		}

		const addProductVariation = () => {
			const subProduct: ISubProduct = {
				// sku: props.productDetail.sku + '-',
				sku: '',
				types: [''],
				typesName: '',
				weight: props.productDetail.weight,
				price: 0,
				unit: 1,
				images: [''],
				isEditField: true,
				isValidSku: false
			}
			subProductArr.value.push(subProduct)
		}

		const onsetSubProductArr = (variationsTypeArr: any, variationDetail: any) => {
			subProductArr.value = [...new Set(mapCartesianSubProduct(variationsTypeArr))] as []
			emit('update:variations', variationDetail)
		}

		watch(() => variationsTypeArr.value, (value) => {
			if(!props.isFirstEdit){
				const lastIndex = variationDetail.value.variationsType.length - 1
				if(props.isVariations && variationDetail.value.variationsType[lastIndex].name === '' && variationDetail.value.variationsType[lastIndex].list.length < 1 ){
					const newVariationsType = variationDetail.value.variationsType.slice(0, lastIndex).map(e => { return [...e.list ]})
					onsetSubProductArr(newVariationsType, variationDetail.value.variationsType.slice(0, lastIndex))
				}else {
					onsetSubProductArr(value, variationDetail.value.variationsType)
				}
			}
		})

		watch(() => subProductArr.value, (value) => emit('update:subProducts', value))

		const onUploadComplete = (upload: any, item: any) => {
			const isValidType = 
			upload.raw.type === 'image/jpeg' || 
			upload.raw.type === 'image/png' || 
			upload.raw.type === 'image/webp'
			const isLimit10mb = upload.raw.size / 1024 / 1024 < 10
			if(isValidType && isLimit10mb){
				item.imageUrl = URL.createObjectURL(upload.raw)
				item.images[0] = upload
			} else {
				item.imageUrl = null
				item.images[0] = null
			}
			
		}

		const validateBeforeUpload = (file: any) => {
			const isValidType = 
				file.type === 'image/jpeg' || 
				file.type === 'image/png' || 
				file.type === 'image/webp'
			const isLimit10mb = file.size / 1024 / 1024 < 10
			
			if (!isValidType) {
				ElNotification({
					title: 'ผิดพลาด',
					message: 'รูปแบบไฟล์ไม่ถูกต้อง',
					type: 'error',
					showClose: !isReactNativeWebview.value
				})
			}
			if (!isLimit10mb) {
				ElNotification({
					title: 'ผิดพลาด',
					message: 'ขนาดรูปภาพต้องไม่เกิน 10mb',
					type: 'error',
					showClose: !isReactNativeWebview.value
				})
			}
			return isValidType && isLimit10mb
		}

		const validateSubProductValidateForm = async () => {
			const isValid = await subProductValidateForm.value?.validate()
			return isValid
		}

		const assignValue = () => {
			if (!props.isEdit) {
				variationDetail.value.variationsType = props.variations ? props.variations as [] : [{ name: '', list: [] }]
			}
			subProductArr.value = props.subProducts as []
			emit('update:isFirstEdit',  false)
		}

		const onReplaceSpecialChar = (item: ISubProduct) => {
			const skuText: string = item?.sku.replaceAll(' ', '') || ''
			subProductArr.value.forEach((e: ISubProduct) => {
				if (e.sku === item?.sku || e.sku === props?.productDetail?.sku) {
					e.isValidSku = true
				} else {
					e.isValidSku = false
				}
			})
			const skuArray = subProductArr.value.filter((e: ISubProduct) => { return e.sku && e.typesName !== item.typesName }).map((e: ISubProduct) => { return e.sku })
			const isDuplicateSku: boolean | undefined = skuArray.includes(item.sku) || item.sku === props?.productDetail?.sku || false
			item.isValidSku = isDuplicateSku
			return skuText
		}

		const validateDuplicate = (rule: any, value: string, callback: any) => {
			const fullValue = props.productDetail.sku + '-' + value
			const skuArray = subProductArr.value.map((e: any) => e.sku)
			skuArray.pop()
			const isInclude = skuArray.some((e: string) => e === fullValue)
			if (fullValue && skuArray.length && isInclude) {
				callback(new Error('รหัสสินค้าซ้ำ กรุณากรอกใหม่'))
			} else {
				callback()
			}
		}

		onMounted(async() => {
			if(props.isFirstEdit){
				await assignValue()
			}
		})

		return {
			productVariationRef,
			variationDetail,
			variableTypeArr,
			subProductArr,
			rules,
			isLastVariationsTypeEmpty,
			isChangeEditVariation,
			subProductValidateForm,
			onReplaceSpecialChar,
			validateSubProductValidateForm,
			addTypeVariation,
			removeVariationType,
			decimalValueChecker,
			addOptionVariations,
			onHandlePrice,
			removeSubProduct,
			validateBeforeUpload,
			onUploadComplete,
			addProductVariation,
			validatePriceVariation,
			validateDuplicate,
		}
	}
})
