import { AxiosResponse } from 'axios'
import axios from './instance'

export type BankDetail = {
	_id: string;
	code: string;
	image: string;
	name: string;
}

export const getBankList = async (): Promise<AxiosResponse<BankDetail[]>> => {
	try {
		const response = await axios.get('/bank')
		return response
	} catch (error) {
		return Promise.reject(error)
	}
}